/**
 * Conexión a graphQL
 */
/* istanbul ignore file */
import {ApolloClient, InMemoryCache, createHttpLink} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
// import {URLS} from '../constants/urls';
import {setContext} from '@apollo/client/link/context';
import {getToken} from './auth';
import {relayStylePagination} from '@apollo/client/utilities';
import {reportError} from '../helpers/crashlytics/fbCrashlytics';
import {RetryLink} from '@apollo/client/link/retry';

const getClient = async (url, onErrorCallback) => {
  const link = createHttpLink({
    uri: url,
  });

  const authLink = setContext(async (_, {headers}) => {
    const token = await getToken();
    return {
      headers: {
        ...headers,
        authorization: token ? `JWT ${token}` : '',
      },
    };
  });

  const errorLink = onError(({networkError, graphQLErrors}) => {
    // console.log('graphQLErrors', graphQLErrors);
    // console.log('networkError', networkError);    // console.log('graphQLErrors', graphQLErrors);
    // console.log('networkError', networkError);
    if (graphQLErrors) {
      graphQLErrors.forEach(({message, locations, path}) => {
        const errorMsg = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
        const error = new Error(errorMsg);
        reportError(
          'graphQL',
          null,
          'onError graphQL',
          error,
          'Error en graphQL',
        );
      });
    }
    if (networkError) {
      const error = new Error(networkError.message);
      reportError(
        'graphQL',
        null,
        'networkError graphQL',
        error,
        'networkError en graphQL',
      );
    }

    graphQLErrors &&
      onErrorCallback &&
      onErrorCallback({networkError, graphQLErrors});
  });

  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: 3000,
      jitter: true,
    },
    attempts: {
      max: 5,
      retryIf: (error, _operation) => {
        console.log('Retry attempt due to:', error);
        return !!error && (error.statusCode >= 500 || error.statusCode === 0);
      },
    },
  });

  const httpLink = createHttpLink({
    uri: url,
    fetchOptions: {
      timeout: 30000, // 30 seconds timeout
    },
  });

  return new ApolloClient({
    link: authLink.concat(retryLink).concat(errorLink).concat(httpLink),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            diaryEntries: relayStylePagination(),
            tags: relayStylePagination(),
            ProgramProgressType: {
              merge: true,
            },
            EvaluationVariableType: {
              merge(existing, incoming, {mergeObjects}) {
                return mergeObjects(existing, incoming);
              },
            },
            ProfileType: {
              merge(existing, incoming, {mergeObjects}) {
                return mergeObjects(existing, incoming);
              },
            },
            //displayDetailGroups: relayStylePagination(),
          },
        },
        ProgramV1: {
          fields: {
            progress: {
              merge: true,
            },
            variable: {
              merge: true,
            },
          },
        },
      },
    }),
  });
};

export default getClient;
