/* istanbul ignore file */

//import * as Sentry from '@sentry/react';
import React, {useEffect, useState} from 'react';
import * as _ from 'lodash';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';
import Config from 'react-native-config';
import {
  initialize,
  pushStateInitialize,
  userInitialize,
} from './hooks/SessionContext';
import CombineContexts from './CombineContexts';
import './constants/IMLocalize';
import {
  getSession,
  getUser,
  notificationInitialize,
  remoteConfigInitialize,
} from './core/initialize/initializeService';
import getClient from './graphql/apollo';
import {theme} from './styles/nativeBase';
import {FLAGS_DEFAULT, getFlags, MODULES} from './core/featureFlagService';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {UPDATE_DEVICE_INFO} from './graphql/queries/users';
import MainNavigator, {navigateTo, getParams} from './navigation/MainNavigator';
import {initFirebase, singOut} from './core/firebase/firebaseAuth';
import Splash from './components/Splash';
import {clearItems} from './hooks/useAsyncStorageData';
import FloatingUserInfo from './components/FloatingUserInfo';
import {defaultChat} from './hooks/ChatContext';
import {reportError} from './helpers/crashlytics/fbCrashlytics';

/* RudderStack JS Web*/
import * as rudderanalytics from 'rudder-sdk-js';
import * as Sentry from '@sentry/react';
import {resetRudder} from './helpers/analytics/fbAnalytics';

const {ENV} = Config;

const AppWeb = () => {
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showGraphqlErrors, setShowGraphqlErrors] = useState(false);
  const [remoteConfig, setRemoteConfig] = useState(null);
  const [user, setUser] = useState(null);
  const [extraInfo, setExtraInfo] = useState({floatAudio: false});
  const [session, setSession] = useState(initialize);
  const {t, i18n} = useTranslation();
  const [recapchaData, setRecapchaData] = useState(null);
  const [featureFlags, setFeatureFlags] = useState(FLAGS_DEFAULT);
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [pushState, setPushState] = useState(pushStateInitialize);
  const [chatOptions, setChatOptions] = useState(defaultChat);
  const [routeLink, setRouteLink] = useState(null);
  const [notificationMessage, setNotificationMessage] = useState({
    title: t('error:generalTitle'),
    message: t('error:generalDsc'),
    type: 'error',
    first: true,
  });
  const [showModalMessage, setShowModalMessage] = useState(false);

  function setStateForNotificationMessage(data) {
    setNotificationMessage(data);
    setShowModalMessage(true);
  }

  const handleDynamicLink = link => {
    if (!link) return;
    const regex = /[?&]([^=#]+)=([^&#]*)/g;
    const params = {};
    let match;

    while ((match = regex.exec(link))) {
      params[match[1]] = match[2];
    }

    if (params.route) {
      setRouteLink(params.route);
      getParams(params.id);
      console.log('handleDynamicLink', params);
    }
  };

  // Recaptcha checar si se sigue ocupando con firebase
  useEffect(() => {
    if (!recapchaData) {
      console.log('recaptcha', recapchaData);
      //setRecapchaData(recaptcha());
    }
  }, [recapchaData]);

  /** Hook for initialize **/
  useEffect(() => {
    async function remoteC() {
      const userFB = initFirebase();
      //await backgroundMessageHandler();
      const remote = await remoteConfigInitialize();
      setRemoteConfig(remote);
      //await initializePlayer();
      setSession(await getSession());
      setUser(await getUser());
      await getGraphClient(remote);
      await initAnalytics(remote);
      // if (FB_ID) {
      //   //console.log('FB_ID', FB_ID);
      //   Settings.setAppID(FB_ID);
      // }
      // await Settings.setAdvertiserTrackingEnabled(true);
      // const dynamicLink = await Linking.getInitialURL();
      // const url = await fetch(dynamicLink).then(res => res.url);
      // console.log('la urlllll', url);
      // AsyncStorage.removeItem('user-language');
      // AsyncStorage.getItem('user-language').then(lang => {});
      // TODO: remove
      /*i18n.on('languageChanged', evt => {
                                                                                                                        //TODO lang: i18n.language update with graphql
                                                                                                                          console.log('languageChanged.evt', evt);
                                                                                                                        });*/
      i18n.changeLanguage('es');
      const metas = document.head.getElementsByTagName('meta');
      for (let i = 0; i < metas.length; i++) {
        if (metas[i].name == 'viewport') {
          metas[i].content += ', user-scalable=no';
        }
      }

      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }

    function initDynamicLinks() {
      // Para obtener el Dynamic Link inicial al cargar la página.
      const initialLink = new URL(window.location.href);
      if (initialLink) {
        handleDynamicLink(initialLink?.href);
      }
    }

    // const unsubscribe = dynamicLinks().onLink(handleDynamicLink);
    // dynamicLinks()
    //   .getInitialLink()
    //   .then(link => {
    //     if (link && link.url) {
    //       handleDynamicLink(link);
    //     }
    //   });
    remoteC().then(() => {});
    initDynamicLinks();
    return () => {
      //unsubscribe();
      // TODO lang: remove
      // i18n.off('languageChanged');
    };
  }, []);

  useEffect(() => {
    updateSessions().then(async () => {});
    // return async () => {
    //   if (notifications) {
    //     await notifications();
    //   }
    // };
  }, [session, user, client]);

  /* TODO: Estas keys no deberían vivir aqui. :(  */
  async function initAnalytics({
    rs_wk = '2AFlAqLdf1udWDmbOia8J0F2tgd',
    rs_dp = 'https://weareautomqwa.dataplane.rudderstack.com',
  }) {
    try {
      rudderanalytics.load(rs_wk, rs_dp);
      rudderanalytics.ready(() => {
        console.log('We are all set!!!');
      });
    } catch (error) {
      console.log('error', error);
      reportError('App.web', {}, 'initAnalytics', error);
    }
  }

  async function updateSessions() {
    if (session !== initialize) {
      await AsyncStorage.setItem('session', JSON.stringify(session));
    }
    if (user !== null) {
      await AsyncStorage.setItem('user', JSON.stringify(user));
      if (user.user !== null) {
        // Sentry.setUser({
        //   id: user?.user?.uid,
        //   email: user?.user?.email,
        //   username: user?.user?.phoneNumber || user.user?.email,
        // });
      }
    }
    if (deviceInfo && deviceInfo.deviceInfo) {
      const flags = await getFlags(deviceInfo, client);
      setFeatureFlags(flags);
      setShowGraphqlErrors(flags[MODULES.GRAPHQL_ERRORS]);
      return () => {};
    } else {
      let notifications = null;
      if (user && client) {
        notifications = notificationInitialize(updateDeviceInfo);
      }
    }
  }

  const updateDeviceInfo = async data => {
    setDeviceInfo(data);
    client
      .mutate({
        mutation: UPDATE_DEVICE_INFO,
        variables: {
          input: {
            ...data,
            deviceInfo: JSON.stringify(data?.deviceInfo),
          },
          profile: {},
        },
      })
      .then(async res => {
        console.log('DeviceInfo updated', res);
        const identifier = user?.user?.uid;
        // user && user.user && (user.user.phoneNumber || user.user.email);
        const profile = res?.data?.updateProfile?.profile;
        const company = profile?.workCompany;
        let profileExtraInfo = {};
        try {
          profileExtraInfo = JSON.parse(profile?.extraInfo);
        } catch (e) {
          console.log('Error parsing extraInfo', e);
          reportError('App.web', {}, 'updateDeviceInfo', e);
        }

        if (identifier) {
          rudderanalytics.identify(
            identifier,
            {
              ...profileExtraInfo,
              uid: user?.user?.uid,
              email: user.user.email || '',
              phone: user.user.phone || '',
              isEmulator: data?.deviceInfo?.isEmulator,
              installerPackageName: data?.deviceInfo?.installerPackageName,
              tags: data?.deviceInfo?.tags,
              companyId: company?.publicId || '',
              companyName: company?.name || '',
              businessModel: company?.businessModel || '',
              dateJoined: profile?.dateJoined,
              // extraInfo: profileExtraInfo,
              environment: ENV,
            },
            null,
          );
          // await logMainScreen('app', pushState);
          console.log('Identify sent as: ' + identifier);
        }
      })
      .catch(e => {
        console.log('Error en updateDeviceInfo', e);
        reportError('graphql', data, 'UPDATE_DEVICE_INFO', e);
      });
  };

  async function getGraphClient({urls}) {
    try {
      console.log('urls 💝💝💝💝💝💝💝', urls.apollo);
      const cl = await getClient(urls.apollo, onGraphqlError);
      setClient(cl);
    } catch (e) {
      console.error('Error en conectarse a graphQL', e);
      reportError('App.web', {}, 'getGraphClient', e);
    }
  }

  async function onGraphqlError({networkError, graphQLErrors}) {
    console.log('onGraphqlError web', {networkError, graphQLErrors});
    let message;
    let title;
    if (graphQLErrors) {
      console.log('el mensaje: ', graphQLErrors?.[0]?.message);
      if (graphQLErrors?.[0]?.message && session?.authenticated) {
        try {
          await singOut();
        } catch (e) {
          //No está logueado con Firebase
          reportError('App.web', {}, 'onGraphqlError', e);
        }
        setSession({...initialize});
        setUser({...userInitialize});
        await clearItems([]);
        await AsyncStorage.setItem('user-language', i18n?.language);
        resetRudder();
        // link to and reset
        await navigateTo('Login', {reset: true});
      }

      if (!showGraphqlErrors) {
        return;
      }
      title = '¡Request Error!';
      message = _.flatMap(graphQLErrors || [], 'message').join('. ');
    } else {
      title = '¡Network Error!';
      message = networkError?.message;
    }
    console.log('onGraphqlError message', message);
    setNotificationMessage({
      title,
      message: message || 'Unhandled error',
      type: 'error',
    });
  }

  if (!client) {
    return null;
  }

  return (
    <CombineContexts
      apollo={{client}}
      notificationMessage={{
        notificationMessage,
        setNotificationMessage: setStateForNotificationMessage,
      }}
      user={{user, setUser}}
      deviceInfo={deviceInfo}
      featureFlags={featureFlags}
      session={{
        session,
        setSession,
      }}
      remoteConfig={remoteConfig}
      extraInfo={{extraInfo, setExtraInfo}}
      pushState={{pushState, setPushState}}
      chatState={{chatOptions, setChatOptions}}
      theme={theme}>
      {loading ? (
        <Splash />
      ) : (
        <MainNavigator routeLink={routeLink} setRouteLink={setRouteLink} />
      )}
      {!loading ? <FloatingUserInfo user={user} /> : null}
      {/*<AlertDropdown
        message={notificationMessage?.message}
        setShowModalMessage={setShowModalMessage}
        showModalMessage={showModalMessage}
    />*/}
      {/* create an empty container that doesn't occup space with id: recaptcha-container */}
      <View
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: 0,
          height: 0,
        }}
        collapsable={false}
        pointerEvents="none"
        id="recaptcha-container"
        testID="recaptcha-container"
      />
    </CombineContexts>
  );
};

Sentry.init({
  dsn: 'https://fcd0c6b4f8a3482ead4f9622ce46f8e2@o264458.ingest.sentry.io/6324457',
  environment: 'Web',
  debug: ENV === 'local' || ENV === 'staging',
  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  // We recommend adjusting this value in production.
  tracesSampleRate: 1.0,
});

export default AppWeb;

/*
 * Sentry NO!
 *
 * */
