import PropTypes from 'prop-types';
import React, {forwardRef} from 'react';
import {Linking} from 'react-native';
import {removeSpace} from '../../helpers/utils/utils';
import {createChatStyles, createMarkdownStyles} from '../../styles/base';
import AnimatableBox from '../AnimatableBox/AnimatableBox';
import Markdown from '../Markdown/Markdown';
import {fonts} from '../../styles/fonts';
import AudioWave from './AudioWave/AudioWave';
import {useNavigation} from '@react-navigation/native';
import {SOUND_LIBRARIES} from '../../constants/codeConstants';
import AudioWaveSoundPlayer from '../Voice/AudioWaveSoundPlayer';

/**
 * Componente  BotMessageText normal
 * canPlay => viene de voiceStreaming. Si es falso no lo muestra, pero si es true si, no debe afectar a el chat normal
 */

const BotMessageText = forwardRef((props, ref) => {
  const {
    id,
    content,
    extraData,
    lastMessageAudio,
    botStyle,
    canPlay = true,
    isText = false, // for autoPlay
    soundLibrary = SOUND_LIBRARIES.TRACK_PLAYER,
    onPlayAudio = () => {},
    onPauseAudio = () => {},
    animation = false,
  } = props;
  const processedMessage = removeSpace(content);
  const chatStyles = createChatStyles();
  const markdownStyles = createMarkdownStyles();
  const navigation = useNavigation();

  //TODO: se hizo esto porque en Android no agarra la fuente quicksand en chat || hay que revisarlo
  const p = {
    fontFamily: fonts.quicksand,
    textAlign: 'left',
    fontSize: 14,
    color: 'white',
  };

  const boxStyle = botStyle
    ? botStyle
    : {...chatStyles.message, ...chatStyles.bot};

  return (
    <AnimatableBox
      ref={ref}
      key={id}
      {...(animation && {animation: 'fadeInLeftBig'})}
      style={boxStyle}>
      <Markdown
        testID={'bot-message-text'}
        key={id}
        onPress={url => {
          const parts = url?.split('/'); // Divide la URL en partes usando "/"
          const lastPart = parts?.[parts?.length - 1]; // Obtiene el último elemento del arreglo
          // hotfix para nom: los dynamic links no funcionan si estas dentro de la app
          switch (lastPart) {
            case 'home':
              navigation.push('Home');
              break;
            case 'tools':
              navigation.navigate('Home', {screen: 'Herramientas'});
              break;
            case 'therapy':
              navigation.navigate('Home', {screen: 'Terapia'});
              break;
            case 'diary':
              navigation.navigate('Diario emocional');
              break;
            default:
              Linking.openURL(url);
              break;
          }
        }}
        styles={{...markdownStyles, p}}>
        {processedMessage}
      </Markdown>
      {extraData?.multimedia?.contentUrl ? (
        <>
          {soundLibrary === SOUND_LIBRARIES.RN_SOUND ? (
            <AudioWaveSoundPlayer
              url={extraData.multimedia.contentUrl}
              bot={true}
              onPlayAudio={onPlayAudio}
              onPauseAudio={onPauseAudio}
              canPlay={canPlay}
              lastMessageAudio={lastMessageAudio?.id === id && isText}
            />
          ) : (
            <AudioWave
              url={extraData.multimedia.contentUrl}
              id={id}
              title="bot"
              lastMessageAudio={lastMessageAudio === id}
            />
          )}
        </>
      ) : null}
    </AnimatableBox>
  );
});

BotMessageText.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.any])
    .isRequired,
  content: PropTypes.string.isRequired,
};
export default BotMessageText;
