import React, {useContext, useEffect, useState} from 'react';
import {Box, Button, Text} from 'native-base';
import BackgroundGradient from '../../components/Background/BackgroundGradient';
import NavigateBar from '../../components/NavigateBar';
import {useTranslation} from 'react-i18next';
import {useNavigation, useRoute} from '@react-navigation/native';
import {
  GET_RESERVATION,
  SET_APPOINTMENT_RESERVATION,
} from '../../graphql/queries/therapy';
import {useMutation, useQuery} from '@apollo/client';
import {
  convertToISOFormat,
  getFormatDateAndHour,
  isIOS,
  isWeb,
} from '../../helpers/utils/utils';
import PrimaryButton from '../../components/PrimaryButton';
import CardExcercise from '../../components/Exercises/CardExcercise';
import MessageSystem from '../../components/MessageSystem';
import {
  PushStateContext,
  RemoteConfigContext,
} from '../../hooks/SessionContext';
import {Linking} from 'react-native';
import Loading from '../../components/Loading';
import {saveCalendar} from '../../core/calendar/calendarServices';
import SmallModalMinimalist from '../../components/SmallModalMinimalist';
import {logMainScreen} from '../../helpers/analytics/fbAnalytics';

const SessionDetailsTherapy = () => {
  const {t, i18n} = useTranslation();
  const navigation = useNavigation();
  const route = useRoute();
  const {pushState} = useContext(PushStateContext);
  const [reservation, setReservation] = useState();
  const [showModalInfo, setShowModalInfo] = useState();
  const [showModalRules, setShowModalRules] = useState();
  const [messageModal, setMessageModal] = useState();
  const [messageRules, setMessageRules] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const {
    remoteConfig: {therapyConfig},
  } = useContext(RemoteConfigContext);

  const {id} = route?.params || {};

  /* graphql */
  const {data, loading} = useQuery(GET_RESERVATION, {
    variables: {
      reservationId: id,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const [setAppointmentReservationAsTaken] = useMutation(
    SET_APPOINTMENT_RESERVATION,
    {
      onError: callbackError,
      onCompleted: callBackSuccess,
    },
  );

  useEffect(() => {
    logMainScreen('session_details_therapy', pushState);
  }, []);

  useEffect(() => {
    if (data) {
      console.log('data', JSON.stringify(data, null, 2));
      setReservation(data?.appointmentReservation);
    }
  }, [data]);

  const dataDetails = {
    title: t('therapy:detailsTitle'),
    contentType: 'session',
    duration: therapyConfig?.timeSession || t('therapy:timeSession'),
    shortDescription:
      therapyConfig?.shortDescription?.[i18n?.language] ||
      t('therapy:sessionDescription'),
    coach: {
      therapistName: reservation?.therapyOption?.label,
      therapistPhoto: reservation?.therapyOption?.image?.cdnUrl,
      approach: reservation?.therapyOption?.subLabel,
      therapistDescription: reservation?.therapyOption?.description,
    },
  };

  const dateFormated = getFormatDateAndHour(
    reservation?.date,
    reservation?.startTime,
    i18n.language,
  );

  function back() {
    navigation.reset({
      index: 0,
      routes: [{name: 'Home'}],
    });
  }

  function showMessageInfo() {
    setMessageModal(t('therapy:saveSuccess'));
    setShowModalInfo(true);
  }

  function errorMessageInfo() {
    setMessageModal(t('lives:needAccess'));
    setShowModalInfo(true);
  }

  function showRules(isRulesTherapy) {
    setShowModalRules(true);
    setMessageRules(
      isRulesTherapy
        ? [t('therapy:ruleTherapy'), t('therapy:ruleTherapy2')]
        : [t('therapy:ruleReschedule'), t('therapy:ruleReschedule2')],
    );
  }

  async function openURL(url) {
    console.log('url', url);
    Linking.canOpenURL(url).then(() => {
      Linking.openURL(url);
    });
  }

  function callbackError(e) {
    console.log('ERROR', e);
  }

  function callBackSuccess(data) {
    console.log('data', JSON.stringify(reservation, null, 2));
    setIsLoading(true);

    if (data?.setAppointmentReservationAsTaken?.success) {
      openURL(reservation?.meetingPatientUrl);
      setIsLoading(false);
    }
  }

  const {endTimeISO} = convertToISOFormat(
    reservation?.fullDateIso,
    therapyConfig?.timeSession,
  );

  if (loading) return <Loading />;

  return (
    <BackgroundGradient bgColor={'white'}>
      <NavigateBar
        backEvent={back}
        bg={'white'}
        headerTitle={t('therapy:titleTherapySession')}
      />

      {reservation === null ? (
        <Box testID="resevation-not-found" my={20} justifyContent={'center'}>
          <Text textAlign={'center'} fontSize={90}>
            🔍
          </Text>
          <Text
            py={3}
            px={15}
            color={'tertiary.100'}
            textAlign={'center'}
            fontSize={isWeb() ? '3xl' : 'xl'}>
            {t('therapy:notFoundAppointment')}
          </Text>
        </Box>
      ) : (
        <Box>
          <Text
            py={5}
            color={'violet.100'}
            fontWeight={800}
            fontFamily={isWeb() ? 'Quicksand' : 'quicksand'}
            textAlign={'center'}>
            {dateFormated}
          </Text>

          <CardExcercise data={dataDetails} />

          <Button
            testID="rules-button"
            onPress={() => {
              showRules(true);
            }}
            ml={0}
            alignSelf={'flex-start'}
            _hover={{bg: 'transparent'}}
            _pressed={{bg: 'transparent'}}
            _text={{
              color: 'primary.100',
              fontWeight: 800,
              fontFamily: isWeb() ? 'Quicksand' : 'quicksand',
            }}
            bg={'transparent'}>
            {t('therapy:rulesTherapy')}
          </Button>

          <Button
            testID="cancel-button"
            onPress={() => {
              showRules(false);
            }}
            _hover={{bg: 'transparent'}}
            _pressed={{bg: 'transparent'}}
            _text={{
              color: 'primary.100',
              fontWeight: 800,
              fontFamily: isWeb() ? 'Quicksand' : 'quicksand',
            }}
            bg={'transparent'}>
            {t('therapy:rulesCancel')}
          </Button>

          {isWeb() && (
            <Text pt={3} color={'tertiary.100'} textAlign={'center'}>
              {t('therapy:webNotice')}
            </Text>
          )}

          <Box mt={5} px={5}>
            <PrimaryButton
              testId={'btn-therapy'}
              extraParams={{
                isLoading,
                isDisabled: isWeb()
                  ? reservation?.meetingPatientUrl === null
                  : false,
              }}
              onPress={() => {
                isWeb()
                  ? openURL(reservation?.meetingPatientUrl)
                  : reservation?.meetingPatientUrl !== null
                  ? setAppointmentReservationAsTaken({
                      variables: {reservationId: id},
                    })
                  : saveCalendar(
                      {
                        title: t('therapy:titleEvent'),
                        presentationDate: reservation?.fullDateIso,
                        endPresentationDate: endTimeISO,
                        url: reservation?.dynamicLink,
                        notes: isIOS()
                          ? t('therapy:notesEvent')
                          : t('therapy:notesEventAndroid', {
                              link: reservation?.dynamicLink,
                            }),
                      },
                      showMessageInfo,
                      errorMessageInfo,
                    );
              }}>
              {isWeb()
                ? t('therapy:startTherapy')
                : reservation?.meetingPatientUrl !== null
                ? t('therapy:startTherapy')
                : t('lives:calendar')}
            </PrimaryButton>
          </Box>
        </Box>
      )}

      <MessageSystem
        isWarning={false}
        message={messageModal}
        setShowModalMessage={setShowModalInfo}
        showModalMessage={showModalInfo}
      />

      <SmallModalMinimalist
        testID={'small-modal'}
        message={messageRules}
        showModal={showModalRules}
        size="xl"
        setShowModal={setShowModalRules}>
        {messageRules?.map((item, index) => (
          <Text key={index}>{item}</Text>
        ))}
      </SmallModalMinimalist>
    </BackgroundGradient>
  );
};

export default SessionDetailsTherapy;
