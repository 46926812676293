// useSocket.js
// noinspection JSIgnoredPromiseFromCall

import {useState, useContext} from 'react';
import {io} from 'socket.io-client';
import {getToken} from '../graphql/auth';
import {DeviceInfoContext, RemoteConfigContext} from './SessionContext';
import {isWeb} from '../helpers/utils/utils';
import {useNetInfo} from '@react-native-community/netinfo';

const useSocket = ({moduleType = 'voice', fromScreen = 'Home'}) => {
  const {
    remoteConfig: {voiceConfig},
  } = useContext(RemoteConfigContext);
  const urlSocket = voiceConfig?.socketURL;
  const errorAttempts = voiceConfig?.attempt;
  const {deviceInfo} = useContext(DeviceInfoContext);

  //console.log('deviceName', JSON.stringify(deviceInfo, null, 2));
  const {type} = useNetInfo();

  const [socket, setSocket] = useState(null);

  async function initializeSocket() {
    const token = await getToken();
    const newSocket = io(urlSocket, {
      auth: {
        token,
      },
      extraHeaders: {
        module: moduleType === 'voice' ? 'stream-audio' : 'stream-text',
        app_name: 'Mindsurf',
        device_model: deviceInfo?.deviceModel,
        os_name: deviceInfo?.OS,
        device_name: deviceInfo?.deviceId,
        app_version: '2', // 1: Jenny, 2: Mindsurf
        network_wifi: type === 'wifi' ? 'true' : 'false',
        os_version: deviceInfo?.systemVersion,
        channel: isWeb() ? 'web' : 'mobile',
        context_app_version: deviceInfo?.version,
        from: fromScreen, // por default Home, si es voz viene de Chat
      },
    });
    console.log('initialized Socket');
    setSocket(newSocket);
  }

  function disconnect() {
    console.log('Disconnect socket...');
    socket.disconnect();
  }

  function emitText(data, callback = () => {}) {
    const network_wifi = type === 'wifi';
    socket.emit(
      'vs_receive_message',
      {
        ...data,
        network_wifi,
      },
      callback,
    );
  }

  function emitAudio(data, callback = () => {}) {
    //socket.emit('audio', data, callback);
    // console.log('emitAudio.socket', !!socket);
    // console.log('emitAudio', data?.length);
    const network_wifi = type === 'wifi';

    socket.emit(
      'vs_receive_audio',
      {
        format: 'base64',
        // test: true,
        audio: data,
        network_wifi,
      },
      callback,
    );
  }

  function emitStartAudio(data, callback = () => {}) {
    console.log('emitStartAudio');
    socket.emit('vs_start_audio', data, callback);
  }

  function emitEndConversation(callback = () => {}) {
    if (socket) {
      console.log('vs_end');
      socket.emit('vs_end', callback);
    }
  }

  return {
    socket,
    initializeSocket,
    emitText,
    emitAudio,
    emitStartAudio,
    disconnect,
    emitEndConversation,
    errorAttempts,
  };
};

export default useSocket;
